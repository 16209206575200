import {
    SHOW_PROFILE,
    } from '../constants'

export  function show_profile(state = { user: {} }, action){
    switch(action.type){
        case SHOW_PROFILE:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state
    }

}