
import React, { Component } from 'react'

export default class Footer extends Component {
    constructor(props) {
        super(props)
        const parsed = window.location.href.match(/login/g);
        // console.log(parsed)
        let url1 = [];
        if (parsed !== null) {
            url1 = parsed
        }
        this.state = {

            url: url1,


        }

    }
    render() {
        if (this.props.location.pathname == '/login' || this.props.location.pathname == '/register') {
            return null;
        }
        return (
            <div>
            <footer className="footer">
                <div className="row align-items-center justify-content-xl-between">
                    <div className="col-xl-6">
                        <div className="copyright text-center text-xl-left text-muted">
                            <p className="text-sm font-weight-500">Copyright 2018 © All Rights Reserved. Adon Dashboard Template</p>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <p className="float-right text-sm font-weight-500">Designed &amp; Passion With: <a href="">Spurko</a></p>
                    </div>
                </div>
            </footer>
        </div>
        )
    }
}

