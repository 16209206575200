import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import queryString from "query-string";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    const parsed = window.location.href.match(/login/g);
    let url1 = [];
    if (parsed !== null) {
      url1 = parsed;
    }
    this.state = {
      url: url1,
    };
  }

  handleClick = (a) => {
    var x, i;
    x = document.querySelectorAll("li.slide");
    for (i = 0; i < x.length; i++) {
      if (i == a) {
      } else {
        x[i].classList.remove("is-expanded");
      }
    }
  };
  render() {
    if (this.props.location.pathname == "/login") {
      // var element1 = document.querySelector("a");
      // alert(element1)
      // element1.classList.remove("app-content");
      // var element2 = document.querySelector("b");
      // element2.classList.remove("side-app");
      // var element3 = document.querySelector("c");
      // element3.classList.remove("main-content");
      // var element4 = document.querySelector("d");
      // element4.classList.remove("container-fluid");
      // var element5 = document.querySelector("d");
      // element5.classList.remove("pt-8");
      return null;
    }

    return (
      <div>
        <aside className="app-sidebar ">
          <div className="sidebar-img">
            <a className="navbar-brand" href="index.html">
              <img
                alt="..."
                className="navbar-brand-img main-logo"
                src="assets/img/brand/logo.jpg"
              />{" "}
              <img
                alt="..."
                className="navbar-brand-img logo"
                src="assets/img/brand/logo.jpg"
              />
            </a>
            <ul className="side-menu">
              <li className="slide" onClick={() => this.handleClick(0)}>
                <Link className="side-menu__item" to="/">
                  <i className="side-menu__icon fe fe-home"></i>
                  <span className="side-menu__label">Dashboard</span>
                </Link>
              </li>
              <li className="slide" onClick={() => this.handleClick(1)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-arrows-alt"></i>
                  <span className="side-menu__label">New Lead</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/task" className="slide-item">
                      New Lead
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(2)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-certificate"></i>
                  <span className="side-menu__label">Account update</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/account" className="slide-item">
                      Account Update
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="slide" onClick={() => this.handleClick(3)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-dharmachakra"></i>
                  <span className="side-menu__label">Offer & Benefits</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/offer" className="slide-item">
                      Offer & Benifits
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="slide" onClick={() => this.handleClick(4)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-layer-group"></i>
                  <span className="side-menu__label">Daily Report</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/viewinquery" className="slide-item">
                      Daily Report
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(5)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon ion-help-buoy"></i>
                  <span className="side-menu__label">Setting</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/update_profile" className="slide-item">
                      Profile Update
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    );
  }
}
export default withRouter(Sidebar);
