// import React, { Component } from "react";
// import routes from "./routes";
// import {
//   withRouter,
//   Route,
//   Switch,
//   BrowserRouter as Router
// } from "react-router-dom";

// import Header from './containers/HeaderContainer'
// import Siderbar from './containers/SidebarContainer'
// import Footer from './containers/FooterContainer'

// class App extends Component {
//   constructor(props){
//     super(props);
//     this.state = {};
//   }
//     render() {
//         return (
//           <div className="app sidebar-mini rtl">
//             {/* <div id="global-loader" ></div> */}
//               <div className="page">
//                 <div className="page-main">
//                 <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
                 
//                   <Siderbar  {...this.props}/>


//                     <div className="app-content ">
//                       <div className="side-app">
//                         <div className="main-content">
//                           <Header {...this.props}  />
//                            <div className="container-fluid pt-8">
//                               <Router>
//                                 <Switch>
//                                   {routes.map((route, idx) => (
//                                     <Route path={route.path} component={route.component} key={idx} />
//                                   ))}
//                                 </Switch>
//                               </Router>
//                               <Footer {...this.props}/>
//                             </div>
//                         </div>
//                       </div>
//                     </div>
                  
                  
                  
//                   </div>
//               </div>
//           </div>
//       );
//     }
//   }
  
//   export default withRouter(App);






import React, { Component, Suspense, lazy, useEffect, createContext, useReducer, useContext } from "react";
import './App.css';
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  BrowserRouter as Router
} from "react-router-dom";
import { checkAuth } from './utils/auth'
import Header from './containers/HeaderContainer'
import Siderbar from './containers/SidebarContainer'
import Footer from './containers/FooterContainer'
import Home from "./containers/MainContainer";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import { Update_Profile } from "./services/constants";
import ProfileUpdate from "./components/ProfileComponent/ProfileUpdate";
import Offer from "./components/Offer/Offer";
import Account from "./components/Account/Account";
import Task from "./components/TaskComponent/Task";
import Track from "./components/TaskComponent/Track";
const Routing = (props) => {
  const history = useHistory()

  useEffect(() => {
    if (history.location.pathname.startsWith('/login') || history.location.pathname.startsWith('/register')) {
      var element1 = document.getElementById("a");
  		element1.classList.remove("app-content");
	  	var element2 = document.getElementById("b");
  		element2.classList.remove("side-app");
	  	var element3 = document.getElementById("c");
  		element3.classList.remove("main-content");
	  	var element4 = document.getElementById("d");
  		element4.classList.remove("container-fluid");
		  var element5 = document.getElementById("d");
  		element5.classList.remove("pt-8");
    }
  }, [])
  
  return (
    <div className="app sidebar-mini rtl">
      {/* <div id="global-loader" ></div> */}
      <div className="page">
        <div className="page-main">
          <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
          <Siderbar  {...props} />
          <div id="a" className="app-content">
            <div id="b" className="side-app">
              <div id="c" className="main-content">
                <Header  {...props} />
                <div id="d" className="container-fluid pt-8">
                  <Switch>
                    <PrivateRoute exact path="/" component={Home}></PrivateRoute>
                    <PrivateRoute exact path="/update_profile" component={ProfileUpdate}></PrivateRoute>
                    {/* <Route exact path="/register" component={Register}></Route> */}
                    <PrivateRoute exact path="/offer" component={Offer}></PrivateRoute>
                    <PrivateRoute exact path="/account" component={Account}></PrivateRoute>
                    <PrivateRoute exact path="/task" component={Task}></PrivateRoute>
                    <PrivateRoute exact path="/track" component={Track}></PrivateRoute>
                   
                
                 <Route exact path="/login" component={Login}></Route>
                  </Switch>
                  <Footer  {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    checkAuth.isAuthenticated
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (

      <div>
        <Routing {...this.props} />
      </div>
    );
  }
}

export default withRouter(App);