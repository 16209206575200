// import React, { useState, useEffect, useContext } from 'react'
// import MaterialTable from 'material-table'
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { checkAuth } from '../../utils/auth';
// import { authput, authget, post, authformpost, authdelete } from '../../utils/service'
// import * as CONSTANT from '../../utils/constant';
// import 'react-toastify/dist/ReactToastify.css';
// import { Link, withRouter } from 'react-router-dom'
// import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';

// import { Button } from '@material-ui/core';
// // var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
// toast.configure();
// export default function Account(props) {
//     const [offer, setOffer] = useState([])
//     useEffect(() => {
//         window.scrollTo(0, 0);
//         abc();
//     }, [])

//     const abc=()=>{
//         authget(`/api/view_offer`)
//         .then((response) => {
//         const tt=response?.data?.data?.filter(gs=>gs?.type=="freelancer")
//             setOffer(tt)

//         })
//         .catch(function (error) {
//             console.log(error);
//         });
//     }

// //    const deleteSubmit = (id) => {
// //             authget(`/api/offer_delete?id=${id}`)
// //                 .then((data) => {
// //                     if(data.data.status == true){
// //                         toast.dark(data.data.msg,
// //                             { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
// //                           abc();
// //                     }else{
// //                         toast.dark(data.data.msg,
// //                             { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
// //                     }

// //                 }).catch((err) => {
// //                     // this.setState({ loading: false });
// //                 })

// //     }
//     return (
//         <div>

//             <div className="page-header mt-0 p-3">
//                 <h3 className="mb-sm-0">Account</h3>
//                 <ol className="breadcrumb mb-0">
//                     <li className="breadcrumb-item">
//                         <Link to="/"><i className="fe fe-home"></i></Link>
//                     </li>
//                     <li className="breadcrumb-item active" aria-current="page">Account</li>
//                 </ol>
//             </div>
//             <div className="row">
//                 <div className="col-md-12">
//                     <div className="card shadow">
//                         <div className="card-header">
//                             <h2 className="mb-0">Account
//                             {/* <Link to="add_offer" className="btn btn-success btn-sm float-right">Add Offer</Link> */}
//                             </h2>
//                         </div>
//                         <div className="card-body">
//                             <MaterialTable
//                                 title="Account"
//                                 columns={[
//                                     { title: 'Offer', field: 'offer' },
//                                     { title: 'Validity Dtate', field: 'valid_date' },
//                                     { title: 'Type', field: 'type' },
//                                 ]}
//                                 data={offer}
//                                 // actions={[
//                                 //     rowData => ({
//                                 //         icon: 'delete',
//                                 //         tooltip: 'Delete Complete Service',
//                                 //         onClick: (event, rowData) => {
//                                 //             deleteSubmit(rowData.id)
//                                 //         },
//                                 //     }),
//                                 // ]}
//                                 options={{
//                                     actionsColumnIndex: -1
//                                 }}
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import { post, authpost, authformpost, authget } from "../../utils/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import * as CONSTANT from "../../utils/constant";
import queryString from "query-string";
import moment from "moment";

toast.configure();

export default function Account(props) {
  let param = props.location.search;
  const parsed = queryString.parse(param);
  const [payment_detail, setPayment_details] = useState([]);
  const [payment_value, setPayment_value] = useState("");

  const [user_id, setUser_id] = useState(parsed.id);

  const freelancer_id = localStorage.getItem("freelancer_id");
  useEffect(() => {
    window.scrollTo(0, 0);
    payment_details();
  }, []);

  const paymentSubmit = (event) => {
    event.preventDefault();
    const data = {
      user_id,
      payment_value,
    };
    authpost(`/api/payment_submit`, data)
      .then((response) => {
        // console.log(response.data);
        payment_details();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const payment_details = () => {
    authget(`/api/payment_list?user_id=${freelancer_id}`)
      .then((response) => {
        console.log(response.data);
        setPayment_details(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="page-header mt-0 p-3">
        <h3 className="mb-sm-0">Payment Details</h3>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="fe fe-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Payment Details
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-header">
              <h2 className="mb-0">Payment Details</h2>
            </div>
            <div className="card-body">
              <MaterialTable
                title="Payment Details"
                columns={[
                  { title: "Transaction amount", field: "transaction_amount" },
                  // { title: 'Remaning Amount', field: 'remaning_amount' },
                  { title: "Purpose", field: "purpose" },
                  { title: "Remark", field: "remark" },
                  {
                    title: "Date & Time",
                    field: "remark",
                    render: (rowData) => (
                      <>
                        {moment(rowData.created_at).format(
                          "MM/DD/YYYY, h:mm a"
                        )}
                      </>
                    ),
                  },
                ]}
                data={payment_detail}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
