import {
    SHOW_PROFILE,
    } from '../constants'
import {authpost,authget} from '../../utils/service'
import ToastService from 'react-material-toast';
const toast = ToastService.new({
    place:'topRight',
    duration:2,
    maxCount:8
  });
export const updateProfile=(data)=>{
    return (dispatch) => {
        authget("/api/ShowUserDetails")
            .then((response) => {
                if (response.data.status == true) {
                    dispatch({ type: SHOW_PROFILE, payload: response.data })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}





