import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import { authget } from '../../utils/service'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';

export default function MainHome() {
    const [user, setUser] = useState(0);
    const [consaltent, setConsaltent] = useState(0);
    const [freelanser, setFreelancer] = useState(0);
    const [service, setService] = useState(0);
    const [city, setCity] = useState(0);
    const [review, setReview] = useState(0);
    const [completeproject, setCompleteproject] = useState(0);
    const [booking, setbooking] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [customizebooking, setCustomizebooking] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const [offerCount, setOfferCount] = useState(0);
    const [taskcount, setTaskcount] = useState(0);
    const [offerData, setOfferData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [taskData, setTaskData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);



    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/freelancerHome`)
            .then((response) => {
                console.log(response?.data)
                setOfferCount(response?.data?.offerCount)
                setTaskcount(response?.data?.taskCount)
                setOfferData(response?.data?.offerData)
                setTaskData(response?.data?.taskData)

            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const data = {
        labels: ['january', 'february', 'March', 'April', 'May', 'Jun', 'July', 'August', 'september', 'october', 'november', 'december'],
        datasets: [
            {
                label: 'Offer',
                data: offerData,
                // borderColor:['#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839','#DE4839'],
                // backgroundColor:['#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB','#5A20CB'],
                borderColor: ['#DE4839'],
                backgroundColor: ['#5A20CB'],
                pointBorderColor: ['#3DBE29'],
                pointBackgroundColor: ['#EDC126']
            },
            {
                label: 'Task',
                data: taskData,
                borderColor: ['#F4BE2C'],
                backgroundColor: ['#35BDD0'],
                pointBorderColor: ['#EDC126'],
                pointBackgroundColor: ['#3DBE29']
            }
        ]
    }
    const value = 30;
    return (
        <div>
            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">Freelancer Dashboard</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Freelancer Dashboard</li>
                </ol>
            </div>
            <div className="row finance-content">
                <div className="col-xl-6 col-md-6">
                    <div className="card shadow text-center">
                        <div className="card-body">
                            <h3 className="mb-3">Total Lead</h3>
                            {/* <div className="chart-circle" data-value="0.75" data-thickness="10" data-color="#ad59ff">
                                <canvas width="128" height="128"></canvas>
                                <div className="chart-circle-value"><div className="text-xxl">75%</div></div>
                            </div> */}
                            <CircularProgressbar value={value} maxValue={1} text={`${offerCount}`} />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6">
                    <div className="card shadow text-center">
                        <div className="card-body">
                            <h3 className="mb-3">Total Offer</h3>

                            <CircularProgressbar value={value} maxValue={1} text={`${taskcount}`} />

                        </div>
                    </div>
                </div>
             
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow">
                        <div className="card-header bg-transparent">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h6 className="text-uppercase text-light ls-1 mb-1">Overview</h6>
                                    {/* <h2 className="mb-0">Monthly Booking</h2> */}
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {/* <canvas id="finance-chart" className="chart-dropshadow h-315"></canvas> */}
                            <Line data={data} />
                        </div>
                    </div>
                </div>
             
            </div>
            {/* <div className="row">
                <div className="col-xl-6">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="card shadow">
                                <div className="card-header bg-transparent">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-light ls-1 mb-1">Overview</h6>
                                            <h2 className="mb-0">Return On Assets</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body finance-chart">
                                    <div className="finance-ratio">
                                        25%
                                    </div>
                                    <canvas id="finance-chart1" className="chart-dropshadow mt-5 h-200"></canvas>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card shadow">
                                <div className="card-header bg-transparent">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-light ls-1 mb-1">Overview</h6>
                                            <h2 className="mb-0">Working Capital Ratio</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body finance-chart">
                                    <div className="finance-ratio">
                                        0.5:1
                                    </div>
                                    <canvas id="finance-chart2" className="chart-dropshadow mt-5 h-200"></canvas>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card shadow">
                                <div className="card-header bg-transparent">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-light ls-1 mb-1">Overview</h6>
                                            <h2 className="mb-0">Return On Equity</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body finance-chart">
                                    <div className="finance-ratio">
                                        32%
                                    </div>
                                    <canvas id="finance-chart3" className="chart-dropshadow mt-5 h-200"></canvas>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card shadow">
                                <div className="card-header bg-transparent">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-light ls-1 mb-1">Overview</h6>
                                            <h2 className="mb-0">Debit Equity Ratio</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body finance-chart">
                                    <div className="finance-ratio">
                                        0.8:1
                                    </div>
                                    <canvas id="finance-chart4" className="chart-dropshadow mt-5 h-200"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="card shadow">
                        <div className="card-header bg-transparent">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h6 className="text-uppercase text-light ls-1 mb-1">Overview</h6>
                                    <h2 className="mb-0">Balance sheet</h2>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="card-body">
                                <div className="grid-margin">
                                    <div className="">
                                        <div className="table-responsive balance-sheet">
                                            <table className="table card-table table-bordered text-nowrap align-items-center">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-lg font-weight-700">Total Assets</td>
                                                        <td className="font-weight-700 text-lg">$ 78,25,256</td>
                                                        <td className=""><span className="bar-chart w-9" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>2,3,5,3,2,3,4,5,6</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-sm font-weight-700">Current Assets</td>
                                                        <td className="font-weight-700">$ 45,26,356</td>
                                                        <td className="w-100"><span className="bar-chart w-9" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>3,4,5,6,2,3,5,3,2</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-sm">Cash</td>
                                                        <td>$ 12,78,256</td>
                                                        <td className="w-100"><span className="bar-chart w-9" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>2,4,5,6,3,5,3,2,3</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-sm">Account Receivable</td>
                                                        <td>$ 15,45,325</td>
                                                        <td className="w-100"><span className="bar-chart w-9" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>5,3,2,3,4,5,6,2,3</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-sm">Invests</td>
                                                        <td>$ 17,02,775</td>
                                                        <td className="w-100"><span className="bar-chart w-9" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>3,2,3,4,5,6,2,3,5</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-sm font-weight-700">Long Term Assets</td>
                                                        <td className="font-weight-700">$ 32,98,900</td>
                                                        <td className="w-100"><span className="bar-chart w-9" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>3,2,3,4,5,6,2,3,5</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="table-responsive mt-4 balance-sheet">
                                            <table className="table card-table table-bordered table-vcenter text-nowrap align-items-center">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-lg font-weight-700">Total Liabilities</td>
                                                        <td className="font-weight-700 text-lg">$ 78,25,256</td>
                                                        <td><span className="bar-chart" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>4,5,6,2,3,5,3,2,3</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-sm font-weight-700">Current Liabilities</td>
                                                        <td className="font-weight-700">$45,62,235</td>
                                                        <td><span className="bar-chart" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>4,5,2,2,3,4,5,6</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-sm font-weight-700">Share Holder Equity</td>
                                                        <td className="font-weight-700">$ 32,63,021</td>
                                                        <td className=""><span className="bar-chart w-9" data-peity='{ "fill": ["#ad59ff","#00d9bf"]}'>3,2,3,4,5,6,2,3</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


        </div>
    )
}
