import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import queryString from 'query-string';
import { checkAuth } from '../../utils/auth';
import { post,authget } from '../../utils/service'
class Header extends Component {
    constructor(props) {
        super(props)
        const parsed = window.location.href.match(/login/g);
        let url1 = [];
        if (parsed !== null) {
            url1 = parsed
        }
        this.state = {
            url: url1,
        }
    }
    componentDidMount(){
        authget(`/api/c`)
        .then((response) => {
            console.log(response.status)
            if(response.status === 401){
                localStorage.removeItem('freelancerToken');
                localStorage.removeItem('name');
                localStorage.removeItem('email');
                localStorage.removeItem('phone');
                localStorage.removeItem('freelancer_id');
               
            }
            
        })
        .catch(function (error) {
            // console.log(error);
        });
    }
    
    signout = () => {
        checkAuth.signout();
        
        localStorage.removeItem('freelancerToken');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('phone');
        localStorage.removeItem('freelancer_id');

        window.location.href = '/#/login';
        
        window.location.reload(true);
    }


    render() {
        if (this.props.location.pathname == '/login' || this.props.location.pathname == '/register') {
            return null;
        }
        
        return (
            <div>
                <div className="p-2 d-block d-sm-none navbar-sm-search">
                    <form className="navbar-search navbar-search-dark form-inline ml-lg-auto">
                        <div className="form-group mb-0">
                            <div className="input-group input-group-alternative">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-search"></i></span>
                                </div>
                                <input className="form-control" placeholder="Search" type="text" />
                            </div>
                        </div>
                    </form>
                </div>
                <nav className="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
                    <div className="container-fluid">
                        <a aria-label="Hide Sidebar" className="app-sidebar__toggle" data-toggle="sidebar" href="#"></a>

                        <a className="navbar-brand pt-0 d-md-none" href="index.html">
                            <img src="assets/img/brand/logo.jpg" className="navbar-brand-img" alt="..." />
                        </a>

                        <ul className="navbar-nav align-items-center">
                            <li className="nav-item dropdown">
                                <a aria-expanded="false" aria-haspopup="true" className="nav-link pr-md-0 mr-md-2 pl-1" data-toggle="dropdown" href="#" role="button">
                                    <div className="media align-items-center">
                                        <span className="avatar avatar-sm rounded-circle"><img alt="Image placeholder" src="assets/img/faces/female/27.jpg" /></span>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                                    <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => {
                                        e.preventDefault();
                                        this.signout()
                                    }}><i className="ni ni-user-run" ></i> <span>Logout</span></a>
                                </div>
                            </li>
                            <li className="nav-item d-none d-md-flex">
                                <div className="dropdown d-none d-md-flex mt-2">
                                    <a className="nav-link full-screen-link pr-0"><i className="fe fe-maximize-2 floating" id="fullscreen-button"></i></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>

            </div>

        )
    }
}
export default withRouter(Header)