import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import { Link, withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import {UserContext} from './../../content/App'
import {
  authpost,
  authput,
  authget,
  post,
  authformpost,
  authdelete,
} from "../../utils/service";
import { Modal } from "react-responsive-modal";
import * as CONSTANT from "../../utils/constant";
toast.configure();

export default function Task() {
  const [users, setUsers] = useState([]);
  const [open, setopen] = useState(false);
  const [open1, setopen1] = useState(false);
  const [userdetails, setUserdetails] = useState([]);
  const [city, setCity] = useState([]);
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [taskList, setTasklist] = useState([]);
  const [description, setDescription] = useState("");
  const [complete_image, setComplete_image] = useState("");

  const freelancer_id = localStorage.getItem("freelancer_id");
  useEffect(() => {
    window.scrollTo(0, 0);
    authget(`/api/viewtask`)
      .then((response) => {
        // console.log(response.data);
        if (response.data.status == true) {
          let alltask = response?.data?.task_all.filter(
            (gs) => gs.taken_id == freelancer_id
          );
          setTasklist(alltask);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onCloseModal = () => {
    setopen(false);
  };

  const onCloseModal1 = () => {
    setopen1(false);
  };

  const Viewuser = (data, city) => {
    setopen(true);
    setUserdetails(data);
    setCity(city);
  };

  const reviewSubmit = (id) => {
    setopen1(true);
    setUserId(id);
  };

  const addWork = (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = new FormData(e.target);
    authformpost("/api/AddCompleteTask", formdata)
      .then((res) => {
        if (res.data.status == false) {
          setLoading(false);

          toast.error(res.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          setLoading(false);

          toast.dark(res.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });

          setopen1(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const statusChange = (id) => {
    authget(`/api/statuschangeu?id=${id}`)
      .then((response) => {
        // console.log(response.data.data);
        if (response.data.status === true) {
          setUsers(response.data.freelancer);
          toast.dark(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.dark(response.data.msg, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setReview(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = {
      review: review,
      id: userId,
    };
    authpost("/api/freelancerReview", formdata)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status == false) {
          setLoading(true);
          toast.error("Freelancer Review Did not Submit", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
        } else {
          setLoading(true);
          toast.dark("Freelancer Review  Submit Successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          });
          setUsers(res.data.user);
          setopen1(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange23 = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  return (
    <div>
      {/* <Modal open={open} onClose={onCloseModal}>
        <h2>View Task </h2>
        <div className="row">
          <div className="col-12" style={{ width: "750px" }}>
            <div className="card shadow">
              <div className="card-header bg-transparent border-0">
                <div className="gallery row">
                  {userdetails !== null ? (
                    <>
                      City: {city.city_name}
                      <br />
                      Pin Code: {userdetails.pin_code}
                      <br />
                      State: {userdetails.state}
                      <br />
                      Extra Phone number: {userdetails.extra_phone_no}
                      <br />
                      <div>
                        {" "}
                        Profile Image:{" "}
                        <a
                          href={`${CONSTANT.URL}/storage/${userdetails.profile_image}`}
                          className="big"
                        >
                          <img
                            src={`${CONSTANT.URL}/storage/${userdetails.profile_image}`}
                            alt=""
                            title="Beautiful Image"
                            style={{ width: "50px" }}
                          />
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <p>Details Not Fillup By Freelancer</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      <Modal open={open1} onClose={onCloseModal1}>
        <h2>Add Work </h2>

        <div className="row">
          <div className="col-12" style={{ width: "750px" }}>
            <div className="card shadow">
              <div className="card-header bg-transparent border-0">
                <form onSubmit={addWork}>
                  <input type="hidden" name="task_id" value={userId} />
                  <label>Description</label>
                  {/* <input type="number" value="" name="user_balance" id="user_balance" className="form-control" placeholder="Enter Payment Balance"  /> */}
                  <textarea
                    className="form-control"
                    name="description"
                    placeholder="Description"
                    required
                    onChange={handleChange23}
                  ></textarea>

                  <label>Iamge</label>
                  <input
                    type="file"
                    name="complete_image"
                    id="complete_image"
                    className="form-control"
                    placeholder="Enter Purpose"
                    required
                  />

                  <br />
                  <center>
                    <button type="submit" className="btn btn-success">
                      {" "}
                      Submit{" "}
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="page-header mt-0 p-3">
        <h3 className="mb-sm-0">View Task</h3>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="fe fe-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View Task
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-header">
              <h2 className="mb-0">View Task</h2>

              {/* <Link
                to="task_asign"
                className="btn btn-success btn-sm"
                style={{ float: "right" }}
              >
                {" "}
                 Add Task
              </Link> */}

              <br />
            </div>
            <div className="card-body">
              <MaterialTable
                title="View Task"
                columns={[
                  { title: "Task Name", field: "task_name" },
                  {
                    title: "Task Image",
                    field: "task_image",
                    render: (rowData) => (
                      // <a
                      //   href={`${CONSTANT.URL}/storage/${rowData.task_image}`}
                      //   target="_blank"
                      // >
                      //   <img
                      //     src={`${CONSTANT.URL}/storage/${rowData.task_image}`}
                      //     style={{ width: 50 }}
                      //   />
                      // </a>

                      <a
                        href={`${CONSTANT.URL}/storage/${rowData.task_image}`}
                        target="_blank"
                        attributes-list
                        download
                      >
                        {" "}
                        Download
                      </a>
                    ),
                  },
                  {
                    title: "Taken By",
                    field: "name",

                    // render: (row) => (
                    //   <div className={row.status ? "active1" : "deactive1"}>
                    //     {row.status === 1 ? "Active" : "Deactive"}
                    //   </div>
                    // ),
                  },

                  {
                    title: "Add Work ",
                    field: "status",
                    render: (row) => (
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => reviewSubmit(row?.id)}
                      >
                        {" "}
                        Add Work
                      </button>
                    ),
                  },
                  {
                    title: "Track",
                    field: "status",
                    render: (row) => (
                      <Link
                        to={`track?id=${row?.id}`}
                        className="btn btn-success btn-sm"
                      >
                        {" "}
                        Track
                      </Link>
                    ),
                  },
                ]}
                data={taskList}
                // actions={[
                //   {
                //     icon: "visibility",
                //     tooltip: "View Consaltent",
                //     onClick: (event, rowData) => {
                //       Viewuser(rowData.get_user1, rowData.get_city1);
                //     },
                //   },
                //   (rowData) => ({
                //     icon: rowData.cat_status === 1 ? "D" : "A",
                //     tooltip: rowData.cat_status === 1 ? "Disactive" : "Active",
                //     onClick: (event, rowData) => statusChange(rowData.user_id),
                //   }),
                // ]}

                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
