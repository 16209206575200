import React, { Component } from 'react'
import {Link,withRouter} from 'react-router-dom'
import axios from 'axios';
import {checkAuth} from '../../utils/auth';
import {post} from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import {toast} from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';
// import {UserContext} from './../../content/App'
toast.configure();

export default class Login extends Component {
    constructor(props){
        super(props);
        window.scrollTo(0, 0);
        this.state = {
            email: '',
            password: '',
            loading:false,
        };
        this.validator = new SimpleReactValidator();
    }

    UNSAFE_componentWillMount(){
        if(checkAuth.isAuthenticated)
            this.props.history.push('/')
    }

    componentDidMount(prevProps) {
            window.scrollTo(0, 0);
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({[e.target.name]:e.target.value});  
    }

    handleSubmit = e => {
        e.preventDefault();
        const data = { 
            email:this.state.email,  
            password:this.state.password,
            role_id:2
          }
          if (this.validator.allValid()) {
            post('/api/adminLogin', data)
            .then((data) => {  
              if(data){
                    if(data.data.error === 'Unauthorised'){
                    //   const id = toast.error('invalid credentials!');
                      toast.dark("invalid credentials!",  
                      {position: toast.POSITION.BOTTOM_CENTER,autoClose:3000})
                      this.setState({loading:false});
                    }else{   
                        // console.log(data.data.response.user.name) 
                        //   checkAuth.authenticate();
                        //   localStorage.setItem('adminToken', data.data.response.token);
                        //   localStorage.setItem('name', data.data.response.user.name);
                        //   localStorage.setItem('admin_id', data.data.response.user.id);
                        //   localStorage.setItem('email', data.data.response.user.email);
                        //   localStorage.setItem('phone', data.data.response.user.phone);
                          this.props.history.push('/login');  
                          window.location.reload();
                    }  
              }  
              else{  
                // this.props.history.push('/login')  
                this.setState({loading:false});

            }  
          }).catch((error)=>{
              console.log(error);
              this.setState({loading:false});
          })  
        }else{
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({loading:false});
        }      
    }

    render() {
        // let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content'); 
        return (
            <div className="bg-gradient-primary">
                <div className="limiter">
                        <div className="container-login100">
                            <div className="wrap-login100 p-5">
                                <form className="login100-form validate-form">
                                    <div className="logo-img text-center pb-3">
                                        <img src="assets/img/brand/logo-dark1.png" alt="logo-img" />
                                    </div>
                                    <span className="login100-form-title">
                                        Admin Login
                                    </span>
                                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                        <input className="input100" type="email" name="email"  onChange={this.handleChange} value={this.state.email} placeholder="Email" />
                                        {this.validator.message('email', this.state.email, 'required',{ className: 'text-danger' })}
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                        <input className="input100" type="email" name="email"  onChange={this.handleChange} value={this.state.email} placeholder="Email" />
                                        {this.validator.message('email', this.state.email, 'required',{ className: 'text-danger' })}
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="Password is required">
                                        <input className="input100" type="password" placeholder="Password" name="password" onChange={this.handleChange}  value={this.state.password} />
                                        {this.validator.message('password', this.state.password, 'required',{ className: 'text-danger' })}
                                        <span className="focus-input100"></span>
                                        <span className="symbol-input100">
                                            <i className="fa fa-lock" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="container-login100-form-btn">
                                        <button className="login100-form-btn btn-primary" type="submit" onClick={this.handleSubmit} disabled={this.state.loading}>{this.state.loading?'Loading...':'Login'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}
